var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "calendar", fluid: "", tag: "section" } },
    [
      _c("base-v-component", {
        attrs: { heading: "Calendar", link: "components/calendars" }
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "12", md: "10" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("August 2019")]),
                      _c("v-spacer"),
                      _vm._l(_vm.types, function(t, i) {
                        return _c(
                          "calendar-btn",
                          {
                            key: i,
                            on: {
                              click: function($event) {
                                _vm.type = t
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " + _vm._s(t) + "\n          "
                            )
                          ]
                        )
                      }),
                      _c("v-spacer"),
                      _c(
                        "calendar-btn",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$refs.calendar.prev()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                        1
                      ),
                      _c(
                        "calendar-btn",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$refs.calendar.next()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                        1
                      )
                    ],
                    2
                  ),
                  _c(
                    "v-sheet",
                    { staticClass: "mt-5", attrs: { height: "600", flat: "" } },
                    [
                      _c("v-calendar", {
                        ref: "calendar",
                        attrs: {
                          events: _vm.events,
                          type: _vm.type,
                          "event-color": "primary",
                          now: "2019-01-08"
                        },
                        model: {
                          value: _vm.calendar,
                          callback: function($$v) {
                            _vm.calendar = $$v
                          },
                          expression: "calendar"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }